import React from "react";
import "./MainContent.scss";
import Channels from "./channel/Channels";
import SideBar from "./side-bar/SideBar";
import {getBlogSettings, getHeaderDesktopAds, isStartPage,} from "../utils/Util";
import BlogRecommendations from "./blog/BlogRecommendations";
import {isTableDevice} from "../utils/DeviceUtil";
import logo4 from "../images/goplay.jpg";
import logo5 from "../images/casinoutansvensklicens.png";
import logo6 from "../images/CasinoUtanGranserNew.png";
import logo7 from "../images/spelpressen.png";
import logo8 from "../images/utan.png";

class MainContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogSettings: {},
    };
  }

  componentDidMount() {
    this.setState({ blogSettings: getBlogSettings() });
  }

  render() {
    const {
      match: { params },
    } = this.props;
    let { blogSettings } = this.state;
    return (
      <div className="main-content">
        {blogSettings.showOnStartPage || true ? (
          <BlogRecommendations
            heading={"ARTIKLAR OCH TIPS"}
            featured={true}
            order={"asc"}
            sort={"tabIndex"}
          />
        ) : null}
        <div className="container">
          <div className="row">
            {getHeaderDesktopAds()}
            {isTableDevice() ? <div id="cncpt-tab_lb1"></div> : ""}
          </div>
          <div className="row timeline-layout">
            <div className={`col-12 col-sm-12 col-md-12 col-lg-9 paddingRight0`}>
              <Channels channelFriendlyUrl={params.channelFriendlyUrl} />
              {isStartPage() ? (
                <section className="sponsorBanner">
                  {/* <span className="title">Samarbeten</span> */}
                  <ul>
                    {/* <li>
                      <a
                        href="https://casinoutankonto.net/casino-utan-svensk-licens/"
                        target="_blank"
                      >
                        <img
                          src={logo1}
                          alt="https://casinoutansvensklicens.co/"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://casinoutansvensklicens.net/"
                        target="_blank"
                      >
                        <img
                          src={logo2}
                          alt="Bäst utländska casinon utan licens"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://xn--bstabettingsidorna-ltb.se/betting-utan-svensk-licens/"
                        target="_blank"
                      >
                        <img
                          src={logo3}
                          alt="Bäst utländska casinon utan licens"
                        />
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="https://goplay.se/casinon/utan-licens/"
                        target="_blank"
                      >
                        <img
                          src={logo4}
                          alt="Goplay"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://casinoutansvensklicens.co/"
                        target="_blank"
                      >
                        <img
                          src={logo5}
                          alt="casinoutansvensklicens"
                        />
                      </a>
                    </li>



                    <li>
                      <a
                        href="https://casinoutangränser.se/utlandska-casinon/"
                        target="_blank"
                      >
                        <img
                          src={logo6}
                          alt="CasinoUtanGränser.se - utländska casino"
                        />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://spelpressen.se/casino-reportage/casino-utan-svensk-licens"
                        target="_blank"
                      >
                        <img
                          src={logo7}
                          alt="Spelpressen.se - casino"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://utansvensklicens.casino/casino-utan-bankid/"
                        target="_blank"
                      >
                        <img
                          src={logo8}
                          alt="casino utan mobilt bankid"
                        />
                      </a>
                    </li>
                  </ul>
                </section>
              ) : null}
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainContent;
